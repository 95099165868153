<template>
  <RouterView/>
</template>

<script>
import {useLangRouter} from '@/extensions/langRouter'

export default {
	name: 'App',
	setup() {
		useLangRouter()
	},
	watch: {
		'$i18n.locale': {
			handler: function(to) {
				this.$beet.fetchCoreData(to)
			},
			immediate: true
		}
	},
	updated() {
		this.$nextTick(() => {
			const el = this.$route.hash ? document.querySelector(this.$route.hash) : null
			el ?
				el.scrollIntoView({behavior: 'smooth', block: 'start'}) :
				window.scrollTo({top: 0, behavior: 'smooth'})
		})
	}
}
</script>
